import _ from 'lodash'
import {
  convertPreset,
  fetchPreset,
  enhanceConfigByRole,
  enhanceStructreWithSnapshot,
  getFormControllerType,
  getExtraMessageText,
} from '../services/form-service'
import { createSuffixedName, EMPTY_EMAIL_ID } from '../../../utils/utils'
import translations, { TranslationsInstance } from '../../../utils/translations'
import { addToHistory, absorbException, withFedops } from '../decorators'
import { EVENTS } from '../../../constants/bi'
import {
  FIELDS,
  ROLE_FORM,
  ROLE_SUBMIT_BUTTON,
  ROLE_MESSAGE,
  ROLE_TITLE,
  FIELDS_ROLES,
} from '../../../constants/roles'
import CoreApi from '../core-api'
import { APP_WIDGET_DEFINITION } from './controller-definition'
import {
  PRESET_TYPES,
  RESPONSIVE_PRESET_TYPES,
  RESPONSIVE_GRID_PRESET_TYPES,
  BASE_RULE_EVENT_SCHEMA,
} from './constants'
import { findPlugin } from '../plugins/utils'
import Experiments from '@wix/wix-experiments'
import { FedopsLogger } from '@wix/fedops-logger'
import {
  COMPONENT_TYPES,
  FormPlugin,
  LIGHTBOX_PRESETS,
  STRIPS_PRESETS,
  BillingPanelReferrer,
  UpgradeAlertType,
  NOTIFICATION_EVENTS,
  FormsFieldPreset,
} from '@wix/forms-common'
import {
  convertContactFormToWixForms,
  isSkinWithFieldTitles,
  isSideLabelSkin,
} from '../services/contact-form-service'
import RemoteApi, {
  CONTACT_FORM_ACTIVITY,
  SUBSCRIPTION_FORM_ACTIVITY,
} from '../../../panels/commons/remote-api'
import { convertSubscribeContactFormToWixForms } from '../services/subscribe-form-service'
import { Rule } from '@wix/ambassador-action-triggers-server/http'
import { captureBreadcrumb } from '../../forms-editor-app/monitoring'
import { FormPreset } from '../../../constants/form-types'
import { convertGetSubscribersFormToWixForms } from '../services/getSubscribersMigration/get-subscribers-service'
import { defaultGSStyle } from '../services/getSubscribersMigration/get-subscribers-style'
import { GSExtraData } from '../services/getSubscribersMigration/get-subscribers-types'

const addedFormsPromisesContainer = {}
const gsLanguageTranslators = {}
export interface AddFormPayload {
  containerRef?: ComponentRef
  targetPageRef?: ComponentRef
  source?: string
  shouldSelectForm?: boolean
  shouldSaveHistory?: boolean
  createCollection?: boolean
  width?: number
  space?: number
}

export default class AddFormApi {
  private biLogger: any
  private boundEditorSDK: BoundEditorSDK
  private coreApi: CoreApi
  private experiments: Experiments
  private ravenInstance
  private fedopsLogger: FedopsLogger
  private remoteApi: RemoteApi

  constructor(
    boundEditorSDK,
    coreApi,
    remoteApi: RemoteApi,
    { biLogger, experiments, ravenInstance, fedopsLogger },
  ) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.experiments = experiments
    this.ravenInstance = ravenInstance
    this.fedopsLogger = fedopsLogger
    this.remoteApi = remoteApi
  }

  public async getRegistrationFormPreset(): Promise<FormPreset> {
    const anyOfAppsInstalled: Array<boolean> = await Promise.all([
      this.coreApi.isBlogInstalled(),
      this.coreApi.isForumInstalled(),
      this.coreApi.isEventsInstalled(),
      this.coreApi.isGroupsInstalled(),
      this.coreApi.isSharedGalleryInstalled(),
      this.coreApi.isFileShareInstalled(),
    ])
    return anyOfAppsInstalled.includes(true)
      ? FormPreset.CUSTOM_REGISTRATION_WITH_COMMUNITY
      : FormPreset.REGISTRATION_FORM
  }

  public addForm(preset: FormPresetName, payload?: AddFormPayload, formSnapshot?: FormSnapshot) {
    return this._addForm(preset, payload, formSnapshot)
  }

  public async addAppWidget(
    pageRef: ComponentRef | PageRef,
    containerDefinition,
  ): Promise<ComponentRef> {
    const dataItemIdPlaceholder = 'data_item_id_placeholder'

    const container = _.merge({}, containerDefinition.data, {
      components: _.get(containerDefinition, 'data.components') || [],
      connections: {
        type: 'ConnectionList',
        items: [
          {
            type: 'ConnectionItem',
            role: ROLE_FORM,
            controllerId: dataItemIdPlaceholder,
            isPrimary: true,
            config: JSON.stringify(containerDefinition.connectionConfig),
          },
        ],
      },
    })

    return this.boundEditorSDK.components.add({
      componentDefinition: <any>_.merge({}, APP_WIDGET_DEFINITION, {
        data: {
          id: dataItemIdPlaceholder,
          controllerType: 'wixForms',
        },
        layout: containerDefinition.data.layout,
        components: [container],
      }),
      pageRef,
    })
  }

  public updateFormCollectionIdADI(formCompRef: ComponentRef, oldCollectionId?: string) {
    if (!oldCollectionId) {
      return
    }
    const compIdAndRealCollectionId = oldCollectionId.split('_')
    if (compIdAndRealCollectionId.length <= 1) {
      return
    }
    const realCollectionId = compIdAndRealCollectionId[1]
    const newCollectionId = `${formCompRef.id}_${realCollectionId}`
    return this.coreApi.setComponentConnection(formCompRef, { collectionId: newCollectionId })
  }

  public waitForAddedForm(formCompRef: ComponentRef): Promise<undefined> {
    return addedFormsPromisesContainer[formCompRef.id]
  }

  public async createAutoCollection(
    componentRef: ComponentRef,
    saveSite: boolean,
  ): Promise<string> {
    this.fedopsLogger.interactionStarted('create-auto-collection')
    if (saveSite) {
      await this.coreApi.saveSite()
    }
    const res = await this.coreApi.createAutoCollection(componentRef, saveSite)
    this.fedopsLogger.interactionEnded('create-auto-collection')
    return res
  }

  private _saveSiteIfNecessary(formRef: ComponentRef): Promise<null> {
    const installedFromAppMarket = !!!formRef // we want to manual save only for app market installation
    return installedFromAppMarket ? this.coreApi.saveSiteIfUnsaved() : Promise.resolve()
  }

  private async _runPostAddFormBasedPluginBehavior({
    selectForm,
    controllerRef,
    formRef,
    targetPageRef,
  }: {
    selectForm: boolean
    controllerRef: ComponentRef
    formRef: ComponentRef
    targetPageRef: ComponentRef | null
  }) {
    const {
      config: { plugins },
    } = await this.coreApi.getComponentConnection(formRef)

    if (selectForm) {
      this.boundEditorSDK.selection.selectComponentByCompRef({
        compsToSelect: [controllerRef],
      })
    }

    if (findPlugin(plugins, FormPlugin.REGISTRATION_FORM) && targetPageRef) {
      // TODO: Move this to reg form plugin app when ready
      const lightboxContainerRef = await this._findChildCompByType(
        targetPageRef,
        COMPONENT_TYPES.LIGHTBOX_CONTAINER,
      )

      if (lightboxContainerRef) {
        this.coreApi.layout.alignChildComponentToCenter({
          containerRef: lightboxContainerRef,
          childRef: controllerRef,
        })
        this.boundEditorSDK.selection.selectComponentByCompRef({
          compsToSelect: [lightboxContainerRef],
        })
      }
    }

    if (findPlugin(plugins, FormPlugin.PAYMENT_FORM)) {
      //tslint:disable-line
      this.coreApi.popNotificationAction({
        // TODO: Move to manage panels
        componentRef: formRef,
        plugins,
        notificationTrigger: NOTIFICATION_EVENTS.PAYMENT_FORM_ADDED,
      })
    }

    if (findPlugin(plugins, FormPlugin.MULTI_STEP_FORM)) {
      //tslint:disable-line
      await this.coreApi.steps.updateMultiStepFormTitles(formRef)
      await this.boundEditorSDK.selection.selectComponentByCompRef({
        compsToSelect: [formRef],
      })
      await this.boundEditorSDK.selection.selectComponentByCompRef({
        compsToSelect: [controllerRef],
      })
    }
  }

  private async _createFormConfig(
    formConfig: ComponentConfig,
    presetName: FormPresetName,
  ): Promise<ComponentConfig> {
    const [formName, msid] = await Promise.all([
      this._getFormName(_.get(formConfig, 'formName'), presetName),
      this.coreApi.getMetaSiteId(),
    ])
    let formLabelId = ''

    try {
      formLabelId = await this._createTag(formName)
    } catch (ex) {}

    const initialConfig = {
      ...formConfig,
      formName,
      msid,
      formLabelId,
      emailId: '',
      labels: [...formConfig.labels, formLabelId],
    }
    return this.coreApi.isResponsive() ? { ...initialConfig, useControllerId: true } : initialConfig
  }

  private async _enhanceField(fieldConfig: ComponentConfig): Promise<ComponentConfig> {
    const customFieldId = await this.coreApi.fields.getCustomFieldForField({
      connectionConfig: fieldConfig,
    })
    if (customFieldId) {
      return { ...fieldConfig, customFieldId }
    }

    return fieldConfig
  }

  private async _addFormEditor({
    presetName,
    formRef,
    targetPageRef,
    selectForm = true,
    saveHistory = true,
    createCollection = true,
    rawPreset,
    saveSite,
  }: {
    presetName: FormPresetName
    formRef: ComponentRef | null
    targetPageRef: ComponentRef | null
    selectForm: boolean
    saveHistory: boolean
    createCollection: boolean
    rawPreset: RawComponentStructure
    saveSite: boolean
  }): Promise<ComponentRef> {
    const installedFromAppMarket = !formRef
    const { structure: formStructure, ancestors } = await this._createFormStructureEditor({
      presetName,
      rawPreset,
      formRef,
    })

    if (!installedFromAppMarket) {
      await this.boundEditorSDK.selection.deselectComponents({ compsToDeselect: [ancestors[0]] })
      await this.boundEditorSDK.components.remove({
        componentRef: ancestors[0],
      })
    }

    const { addedFormRef, controllerRef } = await this._addFormStructureEditor({
      formStructure,
      ancestors,
    })

    this._afterFormAddedEditor({
      controllerRef,
      targetPageRef,
      selectForm,
      saveHistory,
      addedFormRef,
      createCollection,
      saveSite,
    })

    return addedFormRef
  }

  private async _createFormStructureEditor({
    presetName,
    rawPreset,
    formRef,
  }: {
    presetName: FormPresetName
    rawPreset: RawComponentStructure
    formRef: ComponentRef
  }): Promise<{ structure: ComponentStructure; ancestors: ComponentRef[] }> {
    const structure = await this._createDynamicFormDataEditor({ presetName, rawPreset })
    const ancestors = formRef
      ? await this.boundEditorSDK.components.getAncestors({
          componentRef: formRef,
        })
      : []

    const appWidgetStructure = formRef
      ? await this.boundEditorSDK.components.serialize({
          componentRef: ancestors[0],
        })
      : _.merge({}, APP_WIDGET_DEFINITION, {
          layout: { x: 175, y: 0 },
          data: { controllerType: getFormControllerType(structure) },
        })

    return {
      structure: convertPreset(structure, {
        controllerId: 'placeholder-id',
        appWidgetStructure,
        coords: _.pick(appWidgetStructure.layout, ['x', 'y']),
      }),
      ancestors,
    }
  }

  private async _createDynamicFormDataEditor({
    presetName,
    rawPreset,
  }: {
    presetName: FormPresetName
    rawPreset: RawComponentStructure
  }): Promise<RawComponentStructure> {
    const roleEnhancmentMap = {
      [ROLE_FORM]: (config) => this._createFormConfig(config, presetName),
    }
    return enhanceConfigByRole(rawPreset, roleEnhancmentMap)
  }

  private async _addFormStructureEditor({
    formStructure,
    ancestors,
  }: {
    formStructure: ComponentStructure
    ancestors: ComponentRef[]
  }): Promise<{ addedFormRef: ComponentRef; controllerRef: ComponentRef }> {
    const pageRef = ancestors[1] || (await this.boundEditorSDK.pages.getCurrent())
    const controllerRef = await this.boundEditorSDK.components.add({
      componentDefinition: <any>formStructure,
      pageRef,
    })
    const addedFormRef = await this.coreApi.getFormContainerOfAppWidget(controllerRef)
    return { addedFormRef, controllerRef }
  }

  private _afterFormAddedEditor({
    controllerRef,
    targetPageRef,
    selectForm,
    saveHistory,
    addedFormRef,
    createCollection,
    saveSite,
  }: {
    controllerRef: ComponentRef
    targetPageRef: ComponentRef | null
    selectForm: boolean
    saveHistory: boolean
    addedFormRef: ComponentRef
    createCollection: boolean
    saveSite: boolean
  }) {
    this.coreApi.appState.setState([controllerRef])

    this._runPostAddFormBasedPluginBehavior({
      selectForm,
      controllerRef,
      formRef: addedFormRef,
      targetPageRef,
    })

    addedFormsPromisesContainer[addedFormRef.id] = new Promise<void>(async (resolve) => {
      try {
        if (createCollection) {
          await this.createAutoCollection(addedFormRef, saveSite)
          resolve()
        } else {
          await this.coreApi.editDraft(addedFormRef)
          resolve()
        }
      } finally {
        if (saveHistory) {
          await addToHistory(this.boundEditorSDK)
        }
      }
    })
  }

  private async _addFormADI({
    formRef,
    formSnapshot,
    rawPreset,
    presetName,
    createCustomFields,
    width,
    space,
    saveHistory,
  }: {
    formRef: ComponentRef
    formSnapshot: FormSnapshot | undefined
    rawPreset: RawComponentStructure
    presetName: FormPresetName
    createCustomFields: boolean
    width: number | undefined
    space: number | undefined
    saveHistory
  }): Promise<ComponentRef> {
    const { structure: formStructure, ancestors } = await this._createFormStructureADI({
      presetName,
      rawPreset,
      formRef,
      createCustomFields,
      formSnapshot,
      width,
      space,
    })

    const { addedFormRef } = await this._addFormStructureADI({
      formStructure,
      ancestors,
    })

    await this.boundEditorSDK.components.remove({
      componentRef: formRef,
    })
    await this._afterFormAddedADI({
      addedFormRef,
      formSnapshot,
      saveHistory,
    })

    return addedFormRef
  }

  private async _createFormStructureADI({
    presetName,
    rawPreset,
    formRef,
    createCustomFields,
    formSnapshot,
    width,
    space,
  }: {
    presetName: FormPresetName
    rawPreset: RawComponentStructure
    formRef: ComponentRef
    createCustomFields: boolean
    formSnapshot: FormSnapshot
    width: number | undefined
    space: number | undefined
  }): Promise<{
    structure: ComponentStructure
    ancestors: ComponentRef[]
  }> {
    const structure = await this._createDynamicFormDataADI({
      presetName,
      rawPreset,
      formSnapshot,
      createCustomFields,
    })
    const [ancestors, { x, y }] = await Promise.all([
      this.boundEditorSDK.components.getAncestors({
        componentRef: formRef,
      }),
      this.boundEditorSDK.components.layout.get({ componentRef: formRef }),
    ])

    const appWidgetStructure = _.merge({}, APP_WIDGET_DEFINITION, {
      layout: { x, y },
      data: { controllerType: getFormControllerType(structure) },
    })

    return {
      structure: convertPreset(structure, {
        controllerId: 'placeholder-id',
        appWidgetStructure,
        coords: _.pick(appWidgetStructure.layout, ['x', 'y']),
        width: formSnapshot ? null : width,
        space,
      }),
      ancestors,
    }
  }

  private async _createDynamicFormDataADI({
    presetName,
    rawPreset,
    formSnapshot,
    createCustomFields,
  }: {
    presetName: FormPresetName
    rawPreset: RawComponentStructure
    formSnapshot: FormSnapshot
    createCustomFields: boolean
  }): Promise<RawComponentStructure> {
    if (formSnapshot && formSnapshot.formComponent) {
      return enhanceStructreWithSnapshot(rawPreset, formSnapshot)
    }
    const roleEnhancmentMap = {
      [ROLE_FORM]: (config) => this._createFormConfig(config, presetName),
      ..._.reduce(
        FIELDS,
        (acc, role) => {
          acc[role] = (config) =>
            createCustomFields ? this._enhanceField(config) : Promise.resolve(config)
          return acc
        },
        {},
      ),
    }
    return enhanceConfigByRole(rawPreset, roleEnhancmentMap)
  }

  private async _addFormStructureADI({
    formStructure,
    ancestors,
  }: {
    formStructure: Object
    ancestors: ComponentRef[]
  }): Promise<{ addedFormRef: ComponentRef }> {
    const pageRef = ancestors[0]
    const controllerRef = await this.boundEditorSDK.components.add({
      componentDefinition: <any>formStructure,
      pageRef,
    })
    const addedFormRef = (
      await this.boundEditorSDK.components.getChildren({
        componentRef: controllerRef,
      })
    )[0]
    return { addedFormRef }
  }

  private async _afterFormAddedADI({
    formSnapshot,
    addedFormRef,
    saveHistory,
  }: {
    formSnapshot: FormSnapshot
    addedFormRef: ComponentRef
    saveHistory: boolean
  }) {
    if (formSnapshot && _.get(formSnapshot, 'formComponent.config.collectionId')) {
      await this.updateFormCollectionIdADI(
        addedFormRef,
        formSnapshot.formComponent.config.collectionId,
      )
    }

    addedFormsPromisesContainer[addedFormRef.id] = new Promise<void>(async (resolve) => {
      try {
        await this.coreApi.editDraft(addedFormRef)
        resolve()
      } finally {
        if (saveHistory) {
          await addToHistory(this.boundEditorSDK)
        }
      }
    })
  }

  public async createNewFormName(formRef: ComponentRef, config: ComponentConfig) {
    const { formName, formLabelId, labels, preset } = config
    const newFormName = await this._getFormName(formName, preset)
    const { id: newFormLabelId } = await this.coreApi.createTag(newFormName)
    const newLabels = _.filter(
      [...(labels || []), newFormLabelId],
      (labelId) => labelId !== formLabelId,
    )

    await this.coreApi.setComponentConnection(formRef, {
      formName: newFormName,
      formLabelId: newFormLabelId,
      labels: newLabels,
    })
  }

  @absorbException('add-form')
  public async handleFormPasted({
    controllerRef,
    formRef,
    originalFormRef,
  }: {
    controllerRef: ComponentRef
    formRef: ComponentRef
    originalFormRef?: ComponentRef
  }) {
    await this.coreApi.rules.handleDuplicatedRules(controllerRef, formRef, originalFormRef)
    const componentConnection = await this.coreApi.getComponentConnection(formRef)

    const { config } = componentConnection
    await this.createNewFormName(formRef, config)

    await this.coreApi.createAutoCollection(formRef)
  }

  public async preventFormAddition(controllerRef?: ComponentRef) {
    let formComponentRef: ComponentRef

    if (controllerRef) {
      try {
        formComponentRef = await this.coreApi.getFormContainerOfAppWidget(controllerRef)
        await this.coreApi.setComponentConnection(formComponentRef, { isDummyForm: true })
      } catch (err) {}
    }

    const esi = await this.coreApi.getEditorSessionId()
    const { ascendPlan } = await this.coreApi.premium.getCurrentAscendPlan()
    this.coreApi.managePanels.openAddFormPremiumBillingPanel(UpgradeAlertType.FORMS_LIMIT, {
      startBi: {
        form_comp_id: _.get(formComponentRef, 'id', null),
        esi,
        origin: BillingPanelReferrer.NUMBER_OF_FORMS_ALERT,
        current_ascend_plan: ascendPlan,
      },
    })

    if (controllerRef) {
      await this.boundEditorSDK.selection.deselectComponents({ compsToDeselect: [controllerRef] })
      await this.boundEditorSDK.components.remove({ componentRef: controllerRef })
      this.coreApi.saveSite()
    }
  }

  @withFedops('handle-duplicated-form')
  public async handleDuplicatedForm({
    controllerRef,
    originalControllerRef,
  }: {
    controllerRef: ComponentRef
    originalControllerRef?: ComponentRef
  }) {
    captureBreadcrumb({
      message: 'handleDuplicatedForm',
      category: 'add-form',
      data: { controllerRef, originalControllerRef },
    })

    if (_.get(controllerRef, 'id') === _.get(originalControllerRef, 'id')) {
      return
    }

    if (await this.hasExceededFormsCount({ hasNewFormRef: !!controllerRef })) {
      this.preventFormAddition(controllerRef)
      return
    }

    const formRef: ComponentRef = await this.coreApi.getFormContainerOfAppWidget(controllerRef)

    if (!formRef) {
      return
    }

    const originalFormRef: ComponentRef =
      originalControllerRef &&
      (await this.coreApi.getFormContainerOfAppWidget(originalControllerRef))

    this.coreApi.reportBiAppWidgetPasted(formRef)

    try {
      let formRefType
      let originalFormRefType

      if (formRef) {
        formRefType = await this.boundEditorSDK.components.getType({ componentRef: formRef })
      }

      if (originalFormRef) {
        originalFormRefType = await this.boundEditorSDK.components.getType({
          componentRef: originalFormRef,
        })
      }

      captureBreadcrumb({
        message: 'handleDuplicatedForm',
        category: 'add-form',
        data: { formRefType, originalFormRefType },
      })
    } catch (err) {}

    if (await this.coreApi.isMultiStepForm(formRef)) {
      await Promise.all([
        this.coreApi.steps.updateMultiStepFormTitles(formRef),
        this.coreApi.steps.updateConnectionConfigStepsOrder(formRef),
      ])
    }

    await this.handleFormPasted({ controllerRef, formRef, originalFormRef })
    await this.coreApi.selectComponent(controllerRef)
  }

  public async hasExceededFormsCount({
    hasNewFormRef,
  }: {
    hasNewFormRef: boolean
  }): Promise<boolean> {
    const [allFormsRefs, { restrictions }] = await Promise.all([
      this.coreApi.getAllFormsRefs({ shouldExcludeSignupForm: true }),
      this.coreApi.premium.getPremiumRestrictions(),
    ])
    const numberOfCurrentForms = hasNewFormRef ? allFormsRefs.length - 1 : allFormsRefs.length
    return (
      _.get(restrictions, 'forms.limit') !== -1 && numberOfCurrentForms >= restrictions.forms.limit
    )
  }

  public async preventFormAdditionAddPanel(
    presetName: FormPresetName,
    newFormRef: ComponentRef,
  ): Promise<boolean> {
    if (
      _.includes(Object.values(LIGHTBOX_PRESETS), presetName) ||
      _.includes(Object.values(STRIPS_PRESETS), presetName)
    )
      return false
    if (await this.hasExceededFormsCount({ hasNewFormRef: true })) {
      const ancestors = await this.boundEditorSDK.components.getAncestors({
        componentRef: newFormRef,
      })
      const widgetRef = ancestors[0]

      this.preventFormAddition(widgetRef)
      return true
    } else return false
  }

  private async _findChildCompByType(ref, type) {
    const children = await this.boundEditorSDK.components.getChildren({ componentRef: ref })

    const childWithTypes = await Promise.all(
      _.map(children, async (componentRef) => ({
        componentRef,
        type: await this.boundEditorSDK.components.getType({ componentRef }),
      })),
    )

    return _.get(
      _.find(childWithTypes, (child) => type === child.type),
      'componentRef',
    )
  }

  private async _addForm(
    presetName: FormPresetName,
    {
      containerRef = null,
      targetPageRef = null,
      source = null,
      shouldSelectForm = true,
      shouldSaveHistory = true,
      createCollection = true,
      width = null,
      space = null,
    }: AddFormPayload = {},
    formSnapshot?: FormSnapshot,
  ): Promise<ComponentRef> {
    const isEditorFlow = this.coreApi.isClassicEditor() || this.coreApi.isResponsive()
    const shouldProtectAddForm = this.experiments.enabled(
      'specs.crm.FormsEditorSupportTransactions',
    )

    if (isEditorFlow) {
      this.fedopsLogger.interactionStarted('add-form-editor')
    } else {
      this.fedopsLogger.interactionStarted('add-form-adi')
    }

    const addFormSource = source || this.coreApi.originEditorType()

    this.biLogger.log({
      evid: EVENTS.PANELS.addFormPanel.ADD_FORM_START,
      template: presetName,
      form_comp_id: null,
      source_name: addFormSource,
    })

    await this._saveSiteIfNecessary(containerRef)

    const locale = await this.boundEditorSDK.info.getLanguage()
    const rawPreset = await fetchPreset(this.ravenInstance)(presetName, locale, (failReason) =>
      this.coreApi.logFetchPresetsFailed(null, failReason),
    )

    if (!rawPreset) {
      return
    }

    const _addForm = async () => {
      let formRef: ComponentRef
      if (isEditorFlow) {
        formRef = await this._addFormEditor({
          formRef: containerRef,
          targetPageRef,
          presetName,
          rawPreset,
          selectForm: shouldSelectForm,
          saveHistory: shouldProtectAddForm ? false : shouldSaveHistory,
          createCollection,
          saveSite: !shouldProtectAddForm,
        })
      } else {
        formRef = await this._addFormADI({
          formRef: containerRef,
          presetName,
          rawPreset,
          formSnapshot,
          createCustomFields: this.coreApi.isADI(),
          width,
          space,
          saveHistory: !shouldProtectAddForm,
        })
      }

      this.biLogger.log({
        evid: EVENTS.PANELS.addFormPanel.ADD_FORM_COMPLETE,
        template: presetName,
        form_comp_id: await this.coreApi.getFormId(formRef),
        source_name: addFormSource,
      })

      if (isEditorFlow) {
        this.fedopsLogger.interactionEnded('add-form-editor')
      } else {
        this.fedopsLogger.interactionEnded('add-form-adi')
      }
      return formRef
    }

    let formRef: ComponentRef
    if (shouldProtectAddForm) {
      await this.boundEditorSDK.document.transactions.runAndWaitForApproval(async () => {
        formRef = await _addForm()
      })
      this.waitForAddedForm(formRef).then(() => this.coreApi.saveSite())
    } else {
      formRef = await _addForm()
    }

    return formRef
  }

  private async _getFormName(nameFromPreset: string, presetKey: FormPresetName): Promise<string> {
    const title =
      nameFromPreset ||
      translations.t('formName', {
        name: translations.t(`addForm.templates.${presetKey}.label`),
      })
    let otherFormsNames
    if (await this.coreApi.shouldFetchRemoteName()) {
      otherFormsNames = await this.remoteApi.formServiceClient.getSimilarFormNames({
        name: title,
      })
    } else {
      const controllers: any[] = await this.boundEditorSDK.controllers.listAllControllers()
      otherFormsNames = await Promise.all(
        controllers.map(async ({ controllerRef }) => {
          const formRef = await this.coreApi.findConnectedComponent(controllerRef, ROLE_FORM)
          if (!formRef) {
            return ''
          }
          const componentConnection = await this.coreApi.getComponentConnection(formRef)
          return _.get(componentConnection, 'config.formName', '')
        }),
      )
    }
    return createSuffixedName(otherFormsNames, title)
  }

  private async _createTag(formName: string): Promise<string | undefined> {
    return (await this.coreApi.createTag(formName)).id
  }

  private _getPresetsData() {
    const shouldUseGridPresets = this.experiments.enabled('specs.crm.useGridPresets')
    const responsivePresets = shouldUseGridPresets
      ? RESPONSIVE_GRID_PRESET_TYPES
      : RESPONSIVE_PRESET_TYPES

    return this.coreApi.isResponsive()
      ? responsivePresets
      : _.map(PRESET_TYPES, (presetData) =>
          _.isString(presetData) ? { preset: presetData } : presetData,
        )
  }

  public loadInitialPanelData() {
    const presetsData = this._getPresetsData()
    return Promise.resolve({ presetsData, presetKey: presetsData[0].preset })
  }

  public async addContactForm(
    contactFormRef: ComponentRef,
    {
      mobileLayouts,
      desktopLayouts,
      langs,
      payload,
    }: { mobileLayouts; desktopLayouts; langs; payload? },
    debug: boolean = false,
  ): Promise<{ formRef: ComponentRef; controllerRef: ComponentRef }> {
    if (!this.coreApi.isClassicEditor()) {
      return
    }

    const {
      components: { serialize, add },
    } = this.boundEditorSDK
    const insideHoverBox = !!_.get(payload, 'hoverboxRef')
    const pageRef = await this.boundEditorSDK.components.getPage({ componentRef: contactFormRef })
    if (pageRef.id !== 'masterPage') {
      await this.boundEditorSDK.pages.navigateTo({ pageRef, pageLink: null })
    }

    const serializedContactForm = await serialize({
      componentRef: contactFormRef,
    })

    if (insideHoverBox) {
      const { activeModeId, hoverboxRef } = payload
      await this.boundEditorSDK.components.modes.activateComponentMode({
        componentRef: hoverboxRef,
        modeId:
          activeModeId ||
          _.get(
            _.find(serializedContactForm.modes.overrides, { isHiddenByModes: false }),
            'modeIds[0]',
          ),
      })
    }

    const contactFormWithMissingData = await this._addMissingStructureToSerializedContactForm(
      serializedContactForm,
      contactFormRef,
      mobileLayouts && mobileLayouts.length,
    )

    // if (
    //   contactFormWithMissingData.componentType !== COMPONENT_TYPES.DYNAMIC_CONTACT_FORM &&
    //   (contactFormWithMissingData as SubscribeContactForm).props.hiddenPhoneField
    // ) {
    //   this.biLogger.log({ evid: 1111, form_comp_id: contactFormRef.id })
    //   return { formRef: { id: 'SKIP', type: 'DESKTOP' }, controllerRef: null }
    // }

    const formStructure = await this._addContactForm(contactFormWithMissingData, {
      desktopLayouts,
      mobileLayouts,
      payload,
    })

    if (formStructure) {
      let controllerRef = contactFormRef,
        compTranslations,
        componentDefinition: ComponentStructure
      if (insideHoverBox) {
        controllerRef = await add({
          componentDefinition: {
            componentType: 'platform.components.AppController',
            layout: { x: 100, y: 20 },
            data: {
              controllerType: 'wixForms',
              name: 'wix-forms-controller',
              type: 'AppController',
              applicationId: '14ce1214-b278-a7e4-1373-00cebd1bef7c',
            },
            skin: 'platform.components.skins.controllerSkin',
          },
          pageRef,
        })
        componentDefinition = convertPreset(formStructure, {
          controllerId: (
            (await this.boundEditorSDK.components.data.get({
              componentRef: controllerRef,
            })) as any
          ).id,
        })
      } else {
        componentDefinition = convertPreset(formStructure, {
          controllerId: 'placeholder-id',
          appWidgetStructure: _.merge({}, APP_WIDGET_DEFINITION, {
            data: { controllerType: getFormControllerType(formStructure) },
          }),
        })
      }

      const isMultilingual = await this.boundEditorSDK.language.multilingual.isEnabled()
      if (isMultilingual) {
        compTranslations = _.get(serializedContactForm, 'translations')
      }

      await this.boundEditorSDK.components.migrate({
        componentDefinition: <any>componentDefinition,
        componentRef: contactFormRef,
      })
      // migrate function is not resolving when done
      await this._waitDSMigration(contactFormRef)

      if (isMultilingual) {
        await this._translateForm(
          { compTranslations },
          controllerRef,
          contactFormWithMissingData,
          langs,
        )
      }

      //this.boundEditorSDK.selection.selectComponentByCompRef({ compsToSelect: [controllerRef] })

      return {
        formRef: await this.coreApi.findConnectedComponent(controllerRef, ROLE_FORM),
        controllerRef,
      }
    } else {
      console.error(`Contact type '${serializedContactForm.componentType}' not supported`)
    }
  }

  public async addGetSubscribers(
    getSubscribersFormRef: ComponentRef,
    {
      mobileLayouts,
      desktopLayouts,
      payload,
    }: { mobileLayouts; desktopLayouts; payload?; translations },
    debug: boolean = false,
  ): Promise<any> {
    // if (!this.coreApi.isClassicEditor()) {
    //   return
    // }-
    const settings = { ...payload.settings, email: payload.email }

    console.log('settings', settings)
    const {
      components: { serialize, getAncestors, add },
    } = this.boundEditorSDK
    const pageRef = await this.boundEditorSDK.components.getPage({
      componentRef: getSubscribersFormRef,
    })
    if (pageRef.id !== 'masterPage') {
      await this.boundEditorSDK.pages.navigateTo({ pageRef, pageLink: null })
    }

    const serializedGSForm = await serialize({
      componentRef: getSubscribersFormRef,
    })
    const formStructure = await this._addGetSubscribersForm(serializedGSForm, settings, {
      desktopLayouts,
      mobileLayouts,
      payload,
    })
    console.log('formStructure', formStructure)

    if (formStructure) {
      const componentDefinition = convertPreset(formStructure, {
        controllerId: 'placeholder-id',
        appWidgetStructure: _.merge({}, APP_WIDGET_DEFINITION, {
          data: { controllerType: getFormControllerType(formStructure) },
        }),
      })
      let controllerRef

      if (debug) {
        const [gsFormFather] = await getAncestors({ componentRef: getSubscribersFormRef })
        controllerRef = await add({
          componentDefinition: <any>componentDefinition,
          pageRef: gsFormFather,
        })
      } else {
        await this.boundEditorSDK.components.migrate({
          componentDefinition: <any>componentDefinition,
          componentRef: getSubscribersFormRef,
        })
        controllerRef = getSubscribersFormRef
        // migrate function is not resolving when done
        await this._waitDSMigration(controllerRef)
      }

      const formContainer = await this.coreApi.getFormContainerOfAppWidget(controllerRef)
      await this.coreApi.steps.updateMultiStepFormTitles(formContainer)

      return {
        formRef: await this.coreApi.findConnectedComponent(controllerRef, ROLE_FORM),
        controllerRef,
      }
    }
  }

  private async _waitDSMigration(controllerRef: ComponentRef) {
    while (
      (await this.boundEditorSDK.components.getType({ componentRef: controllerRef })) !==
      'platform.components.AppWidget'
    ) {}
  }

  private async _updateFormTranslations(
    { compTranslations, originalData },
    controllerRef: ComponentRef,
    getDataUpdate: (
      {
        componentRef,
        connection,
        translatedData,
      }: { componentRef: ComponentRef; connection: ComponentConnection; translatedData: any },
      index: number,
    ) => Promise<any>,
    langs: string[],
  ) {
    const formContainerRef = await this.coreApi.findConnectedComponent(controllerRef, ROLE_FORM)
    const fields = await this.boundEditorSDK.components.getChildren({
      componentRef: formContainerRef,
    })
    const fieldsWithConfig = await Promise.all(
      fields.map(async (componentRef) => ({
        componentRef,
        connection: await this.coreApi.getComponentConnection(componentRef),
      })),
    )
    const translations = _.pick(compTranslations, langs)
    await Promise.all(
      _.keys(translations).map(async (languageCode) => {
        const translatedData = translations[languageCode]
        if ((originalData as any).dynamicFields) {
          translatedData.dynamicFields = this._handleMismatchFieldsTranslation(
            (originalData as any).dynamicFields,
            translatedData.dynamicFields,
          )
        }

        await Promise.all(
          fieldsWithConfig.map(async ({ componentRef, connection }, index) => {
            const data = await getDataUpdate({ componentRef, connection, translatedData }, index)
            if (!_(data).values().every(_.isEmpty)) {
              return this.boundEditorSDK.components.data.updateInLanguage({
                componentRef,
                data,
                languageCode,
              })
            }
          }),
        )
      }),
    )
  }

  private _handleMismatchFieldsTranslation(
    originalFields: ContactFormField[],
    translationFields: ContactFormField[],
  ): ContactFormField[] {
    if (!translationFields) {
      return originalFields
    } else if (originalFields.length === translationFields.length) {
      return translationFields
    } else if (originalFields.length > translationFields.length) {
      let index = 0
      return originalFields.map((field) => {
        if (field.name === _.get(translationFields[index], 'name')) {
          const translatedField = translationFields[index]
          index += 1
          return translatedField
        } else {
          return field
        }
      })
    } else {
      let index = 0
      return originalFields.map((field) => {
        while (field.name !== _.get(translationFields[index], 'name')) {
          index += 1
        }
        return translationFields[index] || field
      })
    }
  }
  private async _translateForm(
    { compTranslations },
    formRef: ComponentRef,
    serializedContactForm: ComponentStructure,
    langs: string[],
  ) {
    switch (serializedContactForm.componentType) {
      case COMPONENT_TYPES.DYNAMIC_CONTACT_FORM:
        return this._addTranslationsToMigratedContactForm(
          { compTranslations, originalData: serializedContactForm.data },
          formRef,
          serializedContactForm.skin,
          langs,
        )
      default:
        return this._addTranslationsToMigratedSubscribeForm(
          { compTranslations, originalData: serializedContactForm.data },
          formRef,
          langs,
        )
    }
  }

  private async _addTranslationsToMigratedSubscribeForm(
    { compTranslations, originalData },
    formRef: ComponentRef,
    langs,
  ) {
    const customDataUpdate = async (
      {
        connection,
        componentRef,
        translatedData,
      }: { connection; componentRef; translatedData: SubscribeContactForm['data'] },
      _index,
    ) => {
      let data
      if (connection.role === ROLE_TITLE) {
        const componentData = await this.boundEditorSDK.components.data.get({
          componentRef,
        })
        data = getExtraMessageText({
          data: componentData,
          newMessage: translatedData.subscribeFormTitle,
        })
      } else if (_.includes(FIELDS_ROLES, connection.role)) {
        const getFieldLabel = () => {
          switch (connection.config.fieldType) {
            case FormsFieldPreset.FIRST_NAME:
              return translatedData.firstNameFieldLabel
            case FormsFieldPreset.LAST_NAME:
              return translatedData.lastNameFieldLabel
            case FormsFieldPreset.MAIN_EMAIL:
              return translatedData.emailFieldLabel
            case FormsFieldPreset.PHONE:
              return translatedData.phoneFieldLabel
          }
        }
        data = { placeholder: getFieldLabel() }
      }
      return data
    }
    return this._addTranslationsToMigratedForm(
      { compTranslations, originalData },
      formRef,
      customDataUpdate,
      langs,
    )
  }

  private async _addTranslationsToMigratedContactForm(
    { compTranslations, originalData },
    formRef: ComponentRef,
    contactFormSkin: string,
    langs,
  ) {
    const customDataUpdate = async (
      {
        translatedData,
        connection: { role },
        componentRef,
      }: {
        translatedData: DynamicContactForm['data']
        connection: ComponentConnection
        componentRef: ComponentRef
      },
      index,
    ) => {
      if (role === 'input_label_left') {
        const dynamicField = translatedData.dynamicFields[index / 2]
        const componentData = await this.boundEditorSDK.components.data.get({
          componentRef,
        })
        return getExtraMessageText({
          data: componentData,
          newMessage: dynamicField.displayLabel,
        })
      } else if (!isSideLabelSkin(contactFormSkin)) {
        const dynamicField = translatedData.dynamicFields[index]
        return isSkinWithFieldTitles(contactFormSkin)
          ? { label: dynamicField.displayLabel }
          : { placeholder: `${dynamicField.displayLabel}${dynamicField.required ? ' *' : ''}` }
      }
    }
    return this._addTranslationsToMigratedForm(
      { compTranslations, originalData },
      formRef,
      customDataUpdate,
      langs,
    )
  }

  private async _addTranslationsToMigratedForm(
    { compTranslations, originalData },
    formRef,
    customDataUpdate: (
      {
        componentRef,
        connection,
        translatedData,
      }: { componentRef: ComponentRef; connection: ComponentConnection; translatedData: any },
      index: number,
    ) => Promise<any>,
    langs: string[],
  ) {
    const getDataUpdate = async (
      {
        connection,
        componentRef,
        translatedData,
      }: { connection; componentRef; translatedData: DynamicContactForm['data'] },
      index,
    ) => {
      let data
      switch (connection.role) {
        case ROLE_SUBMIT_BUTTON: {
          data = { label: translatedData.submitButtonLabel }
          break
        }
        case ROLE_MESSAGE: {
          const componentData = await this.boundEditorSDK.components.data.get({
            componentRef,
          })
          data = getExtraMessageText({
            data: componentData,
            newMessage: translatedData.successMessage,
          })
          break
        }
        default: {
          data = await customDataUpdate({ connection, componentRef, translatedData }, index)
          break
        }
      }
      return data
    }
    return this._updateFormTranslations(
      { compTranslations, originalData },
      formRef,
      getDataUpdate,
      langs,
    )
  }

  private async _addMissingStructureToSerializedContactForm(
    serializedContactForm: ComponentStructure,
    contactFormRef: ComponentRef,
    hasMobileLayouts: boolean,
  ): Promise<ContactForm> {
    // hover box navigation takes time
    while (!(await this.boundEditorSDK.components.style.get({ componentRef: contactFormRef }))) {}

    let updatedContactForm: ContactForm = <ContactForm>serializedContactForm
    const isHidden = _.get(serializedContactForm, 'mobileHints.hidden')
    const hideForm = () => {
      delete updatedContactForm.mobileStructure
      updatedContactForm = _.merge({}, updatedContactForm, {
        mobileHints: {
          hidden: true,
          type: 'MobileHints',
        },
      })
    }
    if (!isHidden && !hasMobileLayouts) {
      hideForm()
    } else if (!isHidden && !serializedContactForm.mobileStructure) {
      const mobileLayout = await this.boundEditorSDK.components.layout.get({
        componentRef: { id: contactFormRef.id, type: 'MOBILE' },
      })
      if (mobileLayout) {
        updatedContactForm = _.merge({}, updatedContactForm, {
          mobileStructure: {
            layout: mobileLayout,
          },
        })
      } else {
        hideForm()
      }
    }

    const style = await this.boundEditorSDK.components.style.get({ componentRef: contactFormRef })
    updatedContactForm = _.merge({}, updatedContactForm, {
      style,
      skin: style.skin,
    })

    const layout = await this.boundEditorSDK.components.layout.get({ componentRef: contactFormRef })
    updatedContactForm = _.merge({}, updatedContactForm, {
      layout,
    })

    return updatedContactForm
  }

  private async _addContactForm(
    serializedContactForm: ContactForm,
    { desktopLayouts, mobileLayouts, payload },
  ): Promise<RawComponentStructure> {
    const [fontOptions, colorsMap] = await Promise.all([
      this.boundEditorSDK.fonts.getFontsOptions(),
      this.boundEditorSDK.theme.colors.getAll(),
    ])
    let convertedContactForm
    const successWithStepsUserId = 'd749fdbe-686c-4b7d-a0c3-e7d0d59025da'

    const updatedPayload =
      _.get(payload, 'userId') === successWithStepsUserId
        ? _.merge({}, payload, { shouldOptOut: true })
        : payload

    switch (serializedContactForm.componentType) {
      case COMPONENT_TYPES.DYNAMIC_CONTACT_FORM:
        convertedContactForm = convertContactFormToWixForms(
          <DynamicContactForm>serializedContactForm,
          fontOptions,
          colorsMap,
          { desktopLayouts, mobileLayouts, payload: updatedPayload },
        )
        break
      default:
        convertedContactForm = convertSubscribeContactFormToWixForms(
          <SubscribeContactForm>serializedContactForm,
          fontOptions,
          colorsMap,
          { mobileLayouts, desktopLayouts, payload: updatedPayload },
        )
    }

    return this._createDynamicContactFormDataEditor({
      rawPreset: convertedContactForm,
    })
  }

  private async _addGetSubscribersForm(
    serializedGetSubscribedForm: ComponentStructure,
    settings: GetSubscribersSettings,
    { desktopLayouts, mobileLayouts, payload },
  ): Promise<RawComponentStructure> {
    const msid = await this.coreApi.getMetaSiteId()

    const componentStyle =
      _.get(serializedGetSubscribedForm, 'style.style.properties') || defaultGSStyle

    const widgetLanguage = _.lowerCase(settings.language)
    let translator = gsLanguageTranslators[widgetLanguage]
    if (!translator) {
      translator = new TranslationsInstance()
      await translator.init(widgetLanguage)
      gsLanguageTranslators[widgetLanguage] = translator
    }
    const formName = translator.t('preset.getSubscribersTitleText')
    const uniqueFormName = await this._getFormName(formName, 'get-subscribers')
    const hiddenInMobile = _.get(serializedGetSubscribedForm, 'mobileHints.hidden')
    const extraData: GSExtraData = {
      desktopLayouts,
      mobileLayouts,
      payload,
      msid,
      componentStyle,
      hiddenInMobile,
      translator,
      formName: uniqueFormName,
    }

    const rawPreset = convertGetSubscribersFormToWixForms(
      serializedGetSubscribedForm,
      settings,
      extraData,
    )

    return this._convertEmailIdsGSForms({ rawPreset })
  }

  public async convertAllContactForms({
    layouts,
    langs,
    isPublishedRevision,
    activeAutomation,
    hasMoreIterations,
  }: {
    layouts: {
      componentRef: ComponentRef
      mobileLayouts: (FieldLayout | FieldLayout[])[]
      desktopLayouts: (FieldLayout | FieldLayout[])[]

      payload?
    }[]
    langs: string[]
    isPublishedRevision: boolean
    activeAutomation: boolean
    hasMoreIterations: boolean
  }): Promise<void> {
    let skippedForms = 0
    let previousFormIds: { id: string; componentConfig: ComponentConfig }[] = []
    let contactFormMapIds = {}

    const migrateAutomations = isPublishedRevision && activeAutomation

    if (this.coreApi.isADI()) {
      if (migrateAutomations) {
        await this._migrateADIRules(CONTACT_FORM_ACTIVITY)
      }
      return
    }

    if (migrateAutomations) {
      previousFormIds = await Promise.all(
        (
          await this.coreApi.getAllFormsRefsAndConfigs()
        ).map(async ({ componentRef: formRef, componentConfig }) => ({
          id: await this.coreApi.getFormId(formRef),
          componentConfig,
        })),
      )
      contactFormMapIds = await layouts.reduce(async (acc, contactForm) => {
        const currentObject = await acc
        const dataId = (
          (await this.boundEditorSDK.components.data.get({
            componentRef: contactForm.componentRef,
          })) as any
        ).id
        return { ...currentObject, [contactForm.componentRef.id]: dataId }
      }, Promise.resolve({}))
    }

    const convertedForms: { formId: string; controllerId: string }[] = await layouts.reduce<
      Promise<{ formId: string; controllerId: string }[]>
    >(async (acc, contactForm) => {
      const previousForms = await acc
      const { formRef, controllerRef } = await this.addContactForm(contactForm.componentRef, {
        mobileLayouts: contactForm.mobileLayouts,
        desktopLayouts: contactForm.desktopLayouts,
        payload: contactForm.payload,
        langs,
      })

      if (formRef.id === 'SKIP') {
        skippedForms++
        return previousForms
      }

      if (formRef) {
        return [...previousForms, { formId: formRef.id, controllerId: controllerRef.id }]
      } else {
        return previousForms
      }
    }, Promise.resolve([]))

    if (convertedForms.length + skippedForms === layouts.length) {
      if (isPublishedRevision) {
        /*convertedForms.forEach((formId) => {
          this.biLogger.log({
            evid: 1095,
            form_comp_id: formId,
            uuid: this.boundEditorSDK.editor.userPreferences.site
          })
        })*/

        if (migrateAutomations) {
          await Promise.all(
            convertedForms.map((form) =>
              this.remoteApi.addContactFormMapping({
                compDataId: contactFormMapIds[form.controllerId],
                formId: form.formId,
              }),
            ),
          )

          if (hasMoreIterations) {
            await Promise.all(
              convertedForms.map((form) =>
                this.coreApi.setComponentConnection(
                  { id: form.formId, type: 'DESKTOP' },
                  { migrated: true },
                ),
              ),
            )
          } else {
            const actualPreviousFormIds = previousFormIds
              .filter(({ componentConfig }) => !componentConfig.migrated)
              .map((f) => f.id)
            const migratedForms = previousFormIds.filter(
              ({ componentConfig }) => componentConfig.migrated,
            )
            const actualConvertedFormIds = [
              ...migratedForms.map((f) => f.id),
              ...convertedForms.map((f) => f.formId),
            ]
            await this._migrateRulesForContactFormMigration(
              actualConvertedFormIds,
              actualPreviousFormIds,
            )
            await Promise.all(
              migratedForms.map(({ id }) =>
                this.coreApi.setComponentConnection(
                  { id, type: 'DESKTOP' },
                  { migrated: undefined },
                  false,
                ),
              ),
            )
          }
        }
        await this.coreApi.sendAllFormsData()
      }
    } else {
      throw 'could not convert all forms'
    }
  }

  public async convertAllGetSubscribers({
    getSubscribersData,
    isPublishedRevision,
    debug,
    activeAutomation,
  }: {
    getSubscribersData: {
      componentRef: ComponentRef
      mobileLayouts: (FieldLayout | FieldLayout[])[]
      desktopLayouts: (FieldLayout | FieldLayout[])[]
      payload?
    }[]
    isPublishedRevision: boolean
    debug: boolean
    activeAutomation: boolean
  }): Promise<void> {
    let previousFormIds: string[] = []
    const migrateAutomations = isPublishedRevision && activeAutomation

    if (this.coreApi.isADI()) {
      if (migrateAutomations) {
        await this._migrateADIRules(SUBSCRIPTION_FORM_ACTIVITY)
      }
      return
    }

    if (migrateAutomations) {
      previousFormIds = await Promise.all(
        (await this.coreApi.getAllFormsRefs()).map((formRef) => this.coreApi.getFormId(formRef)),
      )
    }

    const convertedFormsIds = await getSubscribersData.reduce<Promise<string[]>>(
      async (acc, gsFormData) => {
        const previousForms = await acc
        const { formRef } = await this.addGetSubscribers(
          gsFormData.componentRef,
          {
            desktopLayouts: gsFormData.desktopLayouts,
            mobileLayouts: gsFormData.mobileLayouts,
            payload: gsFormData.payload,
            translations,
          },
          debug,
        )

        if (formRef) {
          return [...previousForms, formRef.id]
        } else {
          return previousForms
        }
      },
      Promise.resolve([]),
    )

    if (isPublishedRevision) {
      if (migrateAutomations) {
        await this._migrateRulesForGSFormMigration(convertedFormsIds, previousFormIds)
      }
      await this.coreApi.sendAllFormsData()
    }
  }

  private async _migrateADIRules(externalActivity: string) {
    const { externalRules, wixFormsAllForms, wixFormsSpecificForms } =
      await this.remoteApi.getFormsRules(externalActivity)
    if (
      (wixFormsAllForms.length && !externalRules.length) ||
      [...wixFormsAllForms, ...wixFormsSpecificForms].find(
        (rule) => _.get(rule, 'stats.activationCount', 0) > 0,
      )
    ) {
      return
    }
    let updates = []
    if (wixFormsAllForms.length) {
      updates = wixFormsAllForms.map((rule) => this.remoteApi.disableRule(rule))
    }
    updates = [...updates, ...externalRules.map((rule) => this.remoteApi.migrateRule(rule.id))]

    await Promise.all(updates)
  }

  private async _migrateRules(
    contactFormIds: string[],
    previousFormIds: string[],
    externalActivity,
  ) {
    const { externalRules, wixFormsSpecificForms, wixFormsAllForms } =
      await this.remoteApi.getFormsRules(externalActivity)
    let updates = []
    const migrateToSpecificContactForms = () =>
      externalRules.map(async (rule) => {
        const previousFormsSchema = await this._createEventSchema(rule, contactFormIds)
        return this.remoteApi.migrateRule(rule.id, contactFormIds, previousFormsSchema)
      })
    if (wixFormsAllForms.length) {
      if (previousFormIds.length) {
        updates = [
          ...wixFormsAllForms.map(async (rule) => {
            const previousFormsSchema = await this._createEventSchema(rule, previousFormIds)
            return this.remoteApi.migrateRule(rule.id, previousFormIds, previousFormsSchema)
          }),
          ...migrateToSpecificContactForms(),
        ]
      } else {
        updates = [
          ...wixFormsAllForms.map((rule) => this.remoteApi.disableRule(rule)),
          ...externalRules.map((rule) => this.remoteApi.migrateRule(rule.id)),
        ]
      }
    } else if (wixFormsSpecificForms.length || previousFormIds.length) {
      updates = migrateToSpecificContactForms()
    } else {
      updates = externalRules.map((rule) => this.remoteApi.migrateRule(rule.id))
    }
    await Promise.all(updates)
  }
  private async _migrateRulesForContactFormMigration(
    contactFormIds: string[],
    previousFormIds: string[],
  ) {
    return this._migrateRules(contactFormIds, previousFormIds, CONTACT_FORM_ACTIVITY)
  }
  private async _migrateRulesForGSFormMigration(
    contactFormIds: string[],
    previousFormIds: string[],
  ) {
    return this._migrateRules(contactFormIds, previousFormIds, SUBSCRIPTION_FORM_ACTIVITY)
  }

  private async _createEventSchema(rule: Rule, formIds: string[]) {
    const previousSchema = rule.trigger.eventSchema
      ? JSON.parse(rule.trigger.eventSchema)
      : BASE_RULE_EVENT_SCHEMA
    const formsSchemas = await Promise.all(
      formIds
        .map<ComponentRef>((formId) => ({ id: formId, type: 'DESKTOP' }))
        .map(async (formRef) => {
          const [
            fields,
            {
              config: { formName },
            },
          ] = await Promise.all([
            this.coreApi.fields.getFieldsSortByXY(formRef, {
              allFieldsTypes: false,
            }),
            this.coreApi.getComponentConnection(formRef),
          ])
          return fields.reduce((acc, field) => {
            acc[`field:${field.componentRef.id}`] = {
              displayName: `${field.crmLabel} [${formName}]`,
              type: 'string',
            }
            return acc
          }, {})
        }),
    )
    const newSchema = formsSchemas.reduce((acc, schema) => ({ ...schema, ...acc }), previousSchema)
    return JSON.stringify(newSchema)
  }

  private async _createDynamicContactFormDataEditor({
    rawPreset,
  }: {
    rawPreset: RawComponentStructure
  }): Promise<RawComponentStructure> {
    const createContactFormConfig = async (
      formConfig: ComponentConfig,
    ): Promise<ComponentConfig> => {
      const { emailIds } = await this.remoteApi.convertContactFormEmails({
        encryptedEmails: formConfig.emailIds,
      })
      return {
        ...formConfig,
        msid: await this.coreApi.getMetaSiteId(),
        emailIds,
      }
    }
    const roleEnhancmentMap = {
      [ROLE_FORM]: (config) => createContactFormConfig(config),
    }
    return enhanceConfigByRole(rawPreset, roleEnhancmentMap)
  }

  private async _convertEmailIdsGSForms({
    rawPreset,
  }: {
    rawPreset: RawComponentStructure
  }): Promise<RawComponentStructure> {
    const _insertEmails = async (formConfig: ComponentConfig): Promise<ComponentConfig> => {
      let emailIds = [EMPTY_EMAIL_ID]

      const email = _.first(formConfig.emailIds)
      if (email) {
        const { emailId } = await this.remoteApi.insertEmail(email)
        emailIds = [emailId]
      }

      return { ...formConfig, emailIds }
    }
    const roleEnhancmentMap = {
      [ROLE_FORM]: (config) => _insertEmails(config),
    }
    return enhanceConfigByRole(rawPreset, roleEnhancmentMap)
  }
}
